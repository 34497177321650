//  mui
import { Grid, Typography, Box, Avatar, Chip, Button, Stack, IconButton, Divider, useTheme } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useTranslation } from 'react-i18next';
import Conditional from 'common/Conditional';
import { useMe } from 'hooks/useMe';
import { useSnackbar } from 'notistack';

const AvatarCard = ({ props, avatar, handleViewProfile }) => {
    const { t } = useTranslation();
    const { whoami } = useMe();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Box
            container
            boxShadow={3}
            sx={{
                borderRadius: 2,
                p: 2,
                width: '100%',
                background: theme.palette.background.paper,
                height: 'inherit',
                overflow: 'hidden'
            }}
            justifyContent="center"
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                    {t(`${props?.Label}`)}
                </Typography>
            </Grid>
            <Grid item spacing={1} sx={{ display: 'flex', justifyContent: 'center', mt: 4, p: 1 }}>
                <Stack container direction="column" justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: 'flex', flex: 1, p: 1, border: '1px dashed #99dfe3', borderRadius: 50, mb: 2 }}>
                        <Conditional condition={avatar !== ''}>
                            <Avatar sx={{ width: 70, height: 70 }} src={avatar} />
                        </Conditional>
                        <Conditional condition={avatar === ''}>
                            <Avatar sx={{ bgcolor: `#99dfe3`, width: 70, height: 70 }}>
                                {/* {props?.Data && (
                                    <Typography variant="h3" style={{ color: `#673ab7d9` }}>
                                        {`${props?.Data[0]?.userName?.split(' ')[props?.Data[0]?.userName?.split(' ').length - 1][0]}
                                        ${props?.Data[0]?.userName?.split(' ')[0][0]}`}
                                    </Typography>
                                )} */}
                                <Typography variant="h3" style={{ color: `#673ab7d9` }}>
                                    {whoami?.firstName[0]} {whoami?.lastName[0]}
                                </Typography>
                            </Avatar>
                        </Conditional>
                    </Box>
                    <Grid container>
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Stack mt={1} alignItems="center">
                                    {props?.Data && (
                                        <Typography variant="subtitle">{`${props?.Data[0]?.userName.split(' ')[1]} ${
                                            props?.Data[0]?.userName.split(' ')[0]
                                        }`}</Typography>
                                    )}
                                    <Typography variant="subtitle">{whoami?.email}</Typography>
                                    <IconButton
                                        aria-label="navigate"
                                        size="small"
                                        onClick={() => {
                                            try {
                                                handleViewProfile(props?.Data[0]?.userId);
                                            } catch {
                                                enqueueSnackbar(t('AvatarUserError'), {
                                                    variant: 'error'
                                                });
                                            }
                                        }}
                                    >
                                        <ArrowCircleRightIcon style={{ color: '#99dfe3' }} />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.5} justifyContent="center">
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item container xs={5.5}>
                            <Grid xs={12} ml={2}>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle">{whoami?.personFunction[0]?.description}</Typography>
                                    <Typography variant="subtitle">{whoami?.organization?.organizationName}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box
                        mt={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            gap: '15px',
                            '@media (max-width: 950px)': {
                                flexDirection: 'column'
                            }
                        }}
                    >
                        {whoami?.roles.map((item, index) => (
                            <Chip key={index} label={`${t(`${item}`)} `} style={{ background: '#673ab7d9', color: '#eaf8ff' }} />
                        ))}
                    </Box>
                </Stack>
            </Grid>
        </Box>
    );
};

export default AvatarCard;

// ${props?.Data[0]?.userOrganization}
