import axiosInstance from '../axiosInstance';

export const paymentApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`/payment${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, options, cancelToken) =>
        axiosInstance
            .get(
                `/payment${path}`,
                { ...params, ...options },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const pay = async (params) => paymentApi.post('/pay', params);
