import { FormProvider } from 'react-hook-form';
import { TreeView } from '@mui/lab';
import TreeParent from './TreeParent';
import { Popup } from '@grapecity/wijmo.react.input';
import { Stack, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { useEffect } from 'react';
// import { authorizedGenApiCall } from 'api/genApiCalls';
// import { apiDataKeyCOLSUserStateSave } from "api/ApiDatakeys";
import { useSelector } from 'react-redux';
import { apiEntities } from 'apis';

import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { wijmoExtendLastColumn } from 'qubCommon/wijmoHelpers/columnMethods';
import { convertGridToMobile } from 'qubCommon/wijmoHelpers/convertToMobile';

const ColumnPicker = (props) => {
    const { columns, popupInitialized, gridRef, mobileGridRef, methods, webPageKey, showRowHeader } = props;
    const onSubmit = (data) => {
        // saveColumnPickerSettings();
    };

    const saveColumnPickerSettings = async () => {
        const columnsHelp = [];
        columns.forEach((column) => column?.children?.forEach((col) => columnsHelp.push(col)));
        const data = methods?.getValues();
        let submitJSON = '';
        const submitJSON2 = [];
        /* eslint-disable */ for (const [key, value] of Object.entries(data)) {
            const res = columnsHelp.find((item) => item.fldName === key);

            if (res) submitJSON2.push({ fldId: res.fldId, isSelected: value });
            if (res && value) submitJSON += `${res?.fldId},`;
            if (key === 'wijmoRowHeader' && value) submitJSON += `${-999}`;
        }
        const res = await apiEntities.getData({
            dataKey: 'Qub-ColsUserState-Save',
            parameters: {
                webPageKey: webPageKey,
                GridVisibleColIds: submitJSON
            }
        });
    };

    // useEffect(() => {
    //     const handleTabClose = (event) => {
    //         event.preventDefault();
    //         saveColumnPickerSettings();
    //     };

    //     window.addEventListener('beforeunload', handleTabClose);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleTabClose);
    //     };
    // }, []);
    const extendLastColumn = () => {
        wijmoExtendLastColumn(gridRef.current, columns, false);
    };

    const mobileColumnChange = () => {
        try {
            convertGridToMobile(gridRef, mobileGridRef.current, columns);
        } catch {
            console.log('no mobile grid here yet');
        }
    };

    return (
        <Popup initialized={popupInitialized} lostFocus={saveColumnPickerSettings}>
            <Stack style={{ maxHeight: '96vh', overflowY: 'auto' }}>
                <div className="wj-dialog-header Qub-DatagridBringValueView" style={{}}>
                    <div>
                        <div style={{ marginBottom: 0, marginTop: 0, display: 'flex', alignItems: 'center' }}>
                            <ViewColumnIcon sx={{ marginRight: '6px' }} />
                            <Typography sx={{ display: 'inline' }} variant="body6">
                                Στήλες
                            </Typography>
                        </div>
                    </div>
                    <button type="button" style={{ cursor: 'pointer' }} tabIndex={-1} className="close wj-hide">
                        &times;
                    </button>
                </div>
                <Stack sx={{ padding: 2 }}>
                    <FormProvider {...methods}>
                        <TreeView
                            aria-label="multi-select"
                            defaultCollapseIcon={<Remove />}
                            defaultExpandIcon={<Add />}
                            defaultExpanded={columns?.map((c) => c.grpDescr)}
                            multiSelect
                            sx={{}}
                        >
                            <FormControlLabel
                                style={{ marginLeft: 20 }}
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                gridRef.current.headersVisibility = 'All';
                                                methods.setValue('wijmoRowHeader', true);
                                                wijmoExtendLastColumn(gridRef.current, columns, false, false, 'enable');
                                            } else {
                                                methods.setValue('wijmoRowHeader', false);
                                                gridRef.current.headersVisibility = 'Column';
                                                wijmoExtendLastColumn(gridRef.current, columns, false, false, 'disable');
                                            }
                                            mobileColumnChange();
                                        }}
                                        defaultChecked={showRowHeader}
                                        size="small"
                                        style={{
                                            width: '20px',
                                            marginLeft: 5,
                                            marginRight: 5,
                                            padding: 0
                                        }}
                                    />
                                }
                                label={<Typography fontWeight="bold">A/A</Typography>}
                            />
                            {columns?.map((category) => (
                                <TreeParent
                                    methods={methods}
                                    key={category.grpDescr}
                                    category={category}
                                    gridRef={gridRef}
                                    extendLastColumn={extendLastColumn}
                                    mobileColumnChange={mobileColumnChange}
                                />
                            ))}
                        </TreeView>
                    </FormProvider>
                    <Button onClick={() => methods.handleSubmit(onSubmit)()} />
                </Stack>
            </Stack>
        </Popup>
    );
};

export default ColumnPicker;
