import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import OptionCard from 'ui-component/cards/OptionCard';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from 'hooks/useBreadcrumbs';

const CommonIndex = () => {
    const [choices, setChoices] = useState([]);
    const { menuItems } = useSelector((state) => state.menu);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const findByPathname = (path, children, idx = 0) => {
        const item = children[idx];
        if (!item) return null;
        if (item.url === path) {
            return item;
        }

        const newChildren = item?.children?.length ? [...children, ...item.children] : children;

        return findByPathname(path, newChildren, idx + 1);
    };

    useEffect(() => {
        try {
            menuItems.forEach((element) => {
                // eslint-disable-next-line no-var
                var res = findByPathname(window.location.pathname, element.children);
                if (res) {
                    setChoices(res);
                }
                // setChoices(...choices, x.children);
            });
        } catch (err) {
            console.log(err);
        }
    }, [navigate]);

    const navigateTo = (url) => {
        navigate(url);
    };

    return (
        <div>
            <Typography sx={{ mb: 4, mt: 2 }} variant="h2">
                {t(choices?.title ?? '')}
            </Typography>
            {choices?.children?.map((option) => (
                <OptionCard clickAction={() => navigateTo(option.url)} key={option.id} description={t(option.title)} />
            ))}
        </div>
    );
};

export default CommonIndex;
