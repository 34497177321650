import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Box,
    Button,
    CssBaseline,
    Divider,
    Grid,
    IconButton,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSettings } from 'hooks/useSettings';
import { Outlet, useNavigate } from 'react-router-dom';
import { Logo } from 'ui-component';
// import XIcon from '@mui/icons-material/X';
import LanguageIcon from '@mui/icons-material/Language';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuIcon from '@mui/icons-material/Menu';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { LanguageSelector, PublicFooter } from './components';
import { useTranslation, Trans } from 'react-i18next';

const languageToString = (language) => {
    if (language === 'gre') {
        return 'ΕΛ';
    }
    if (language === 'en') {
        return 'EN';
    }
    return '';
};

const Main = styled('main')(({ theme }) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: '10vh'
}));

const PublicLayout = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { projectLogo, url, email, projectFullTitle, address, phone } = useSettings();
    const { language } = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const mdUp = useMediaQuery(theme.breakpoints.up('md'));
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const scrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '0.4em',
            height: '0.4em'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b8cbd4'
        }
    };

    const handleNavLogin = () => {
        navigate('/login');
    };

    const handleNavFederation = () => {
        window.open(url, '_blank');
        window.focus();
    };

    const handleSendEmail = () => {
        window.open(`mailto:${email}`, '_blank');
    };

    const handleNavMenu = (url) => {
        navigate(url);
    };

    const handleOpenLanguage = (event) => {
        setOpen((prev) => !prev);
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.paper,
                    boxShadow: 3
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        height: '4vh',
                        background: '#7eaaca',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        px: lgUp ? 26 : 2
                    }}
                >
                    <Stack direction="row">
                        {/* <IconButton>
                            <FacebookIcon sx={{ color: '#f0f0f0' }} />
                        </IconButton>
                        <IconButton>
                            <InstagramIcon sx={{ color: '#f0f0f0' }} />
                        </IconButton>
                        <IconButton>
                            <YouTubeIcon sx={{ color: '#f0f0f0' }} />
                        </IconButton> */}
                        <IconButton onClick={handleSendEmail}>
                            <MailOutlineIcon sx={{ color: '#f0f0f0' }} />
                        </IconButton>
                        <IconButton onClick={handleOpenLanguage} ref={anchorRef}>
                            <Stack direction="row" spacing={1}>
                                <LanguageIcon sx={{ color: '#f0f0f0' }} />
                                <Typography fontSize="large" sx={{ color: '#f0f0f0' }}>
                                    {languageToString(language)}
                                </Typography>
                            </Stack>
                            <LanguageSelector anchorRef={anchorRef} open={open} setOpen={setOpen} language={language} />
                        </IconButton>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        // width: '100%',
                        pl: mdUp ? '20vw' : '2vw'
                        // px: lgUp ? 22 : 2
                        // ,px: lgUp ? 26 : 2
                    }}
                >
                    {/* <Stack
                    direction="row"
                    spacing={2}
                    justifyContent={lgUp ? 'space-between' : 'flex-start'}
                    alignItems="center"
                    sx={{ width: '100%', mt: 1, pl: lgUp ? 25 : 5 }}
                > */}
                    <Grid item xs={12} md={3} pr={2} pt={1}>
                        <Logo logo={projectLogo} dimensions={{ width: '13vh', height: '13vh' }} />
                    </Grid>
                    <Grid container xs={12} md={9}>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="div" fontSize="x-large" fontWeight={400} sx={{ flexGrow: 1 }}>
                                {projectFullTitle}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle" component="div" fontSize="medium" fontWeight={400} sx={{ flexGrow: 1 }}>
                                {address}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                {/* <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', px: 22 }}>
                        <Typography variant="h4" component="div" fontSize="x-large" fontWeight={400} sx={{ flexGrow: 1 }}>
                            {projectFullTitle}
                        </Typography>
                        <Typography variant="h5" component="div" fontSize="x-large" fontWeight={400} sx={{ flexGrow: 1 }}>
                            {address}
                        </Typography>
                    </Stack> */}
                {/* </Stack> */}
                {lgUp ? (
                    <Toolbar>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', px: 22 }}>
                            <Box sx={{ flexGrow: 1, overflowX: 'auto', mr: 2, ...scrollbarStyles }}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/calendar')}>
                                        {t('Calendar')}
                                    </Button>
                                    {/* <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/athletes')}>
                                        Athletes
                                    </Button>
                                    <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/officials')}>
                                        Officials
                                    </Button>
                                    <Button variant="text" color="inherit" onClick={() => handleNavMenu('/public/clubs')}>
                                        Clubs
                                    </Button> */}
                                    <Button variant="text" color="inherit" onClick={handleNavFederation}>
                                        {t('GoToFederation')}
                                    </Button>
                                </Stack>
                            </Box>
                            <Button
                                variant="contained"
                                sx={{ background: '#4d79bc', ':hover': { background: '#79AEC9', color: '#fff' } }}
                                onClick={handleNavLogin}
                            >
                                {t('MemberLogin')}
                            </Button>
                        </Stack>
                    </Toolbar>
                ) : (
                    <Accordion sx={{ width: '100%', mt: 1 }}>
                        <AccordionSummary
                            expandIcon={<MenuIcon />}
                            sx={{
                                flexDirection: 'row-reverse',
                                borderTop: 'none',
                                '& .MuiAccordionSummary-content': {
                                    marginLeft: 1
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                    marginLeft: 1
                                }
                            }}
                        >
                            <Typography fontSize="medium" fontWeight={400}>
                                Menu
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction="column" alignItems="flex-start" spacing={1} sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                                <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/calendar')}>
                                    {t('Calendar')}
                                </Typography>
                                {/* <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/athletes')}>
                                    Athletes
                                </Typography>
                                <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/officials')}>
                                    Officials
                                </Typography>
                                <Typography fontSize="medium" fontWeight={300} onClick={() => handleNavMenu('/public/clubs')}>
                                    Clubs
                                </Typography> */}
                                <Typography fontSize="medium" fontWeight={300} onClick={handleNavFederation}>
                                    {t('GoToFederation')}
                                </Typography>
                                <Divider />
                                <Typography fontSize="medium" fontWeight={300} onClick={handleNavLogin}>
                                    {t('MemberLogin')}
                                </Typography>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )}
            </AppBar>
            <Stack sx={{ width: '100%', overflowX: 'hidden' }}>
                <Main
                    sx={{
                        background: theme.palette.background.default,
                        mt: '28vh'
                    }}
                    style={{ boxSizing: 'content-box' }}
                    theme={theme}
                >
                    <Stack spacing={1} sx={{ minHeight: '70vh' }} justifyContent="space-between">
                        <Outlet />
                        <PublicFooter desktop={lgUp} />
                    </Stack>
                </Main>
            </Stack>
        </Box>
    );
};

export default PublicLayout;
