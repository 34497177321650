//  mui
import { Stack, Grid, Typography, Box, MenuItem, MenuList, ListItemIcon } from '@mui/material';

import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@emotion/react';

const CasualCard = ({ props, handleClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Box
            container
            boxShadow={3}
            sx={{
                borderRadius: 2,
                p: 2,
                width: '100%',
                background: theme.palette.background.paper
            }}
        >
            <Grid
                item
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                }}
            >
                <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                    {t(`${props?.Label}`)}
                </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <MenuList style={{ height: 'inherit' }} variant="menu">
                    {props?.Data?.map((value, index) => (
                        <MenuItem className="menuitem" key={index} sx={{ height: '11vh', mb: 0 }} onClick={() => handleClick(value)}>
                            {/* <ListItemIcon>
                                <CircleIcon fontSize="small" style={{ color: '#99dfe3' }} />
                            </ListItemIcon> */}
                            <Typography variant="h2" pl={1} pr={1} sx={{ color: theme.palette.text.primary }}>
                                {value.child[1].Value} / {value.child[0].Value}
                            </Typography>
                            <Typography variant="body2">{t(`${value.Datakey}`)}</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body2">
                    {t(`Confirmed`)} / {t(`Total`)}
                </Typography>
            </Grid>
        </Box>
    );
};

export default CasualCard;
