import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Spice } from '@qubiteq/qublib';

const PageTitle = ({ title }) => (
    <Stack spacing={2} direction="row">
        <Spice />
        <Typography variant="h2" sx={{ fontSize: '2rem', fontWeight: 400 }}>
            {title}
        </Typography>
    </Stack>
);

PageTitle.propTypes = {
    title: PropTypes.string
};

export default PageTitle;
