/* eslint-disable */
import { InputAdornment, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { useQubBringValue2 } from 'context/BringValueContext/bringValueContext';
import { useFormContext } from 'react-hook-form';
import TextInput from './TextInput';
import { useTranslation } from 'react-i18next';

const BringValueInput = (props) => {
    const { dataKey, onClear, onSelect, name, searchField = 'searchValue', filters, ...other } = props;
    const { t } = useTranslation();
    const { openBringValue, dataLoading } = useQubBringValue2();
    const { getValues, setValue } = useFormContext();

    const handleSelect = (value) => {
        if (onSelect && typeof onSelect === 'function') onSelect(value);
        setValue(name, value[searchField]);
        setValue(`${name}Id`, value.Id);
    };

    const handleClear = () => {
        if (onClear && typeof onClear === 'function') onClear();
        setValue(name, null);
        setValue(`${name}Id`, null);
    };

    const hanleBringValue = () => {
        if (!other.disabled)
            openBringValue({
                dataKey,
                searchField,
                filters,
                searchValue: getValues(name),
                title: 'Sample',
                onSelect: (value) => {
                    handleSelect(value);
                }
            });
    };

    const handleAutocomplete = (e) => {
        if (!e.nativeEvent?.inputType) hanleBringValue();
    };

    const handleChange = (e) => {
        if (!other.preventExecOnAutocomplete) {
            handleAutocomplete(e);
        }
        if (other.onChange && typeof other.onChange === 'function') other.onChange(e);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') hanleBringValue();
    };

    return (
        <TextInput
            onKeyPress={(e) => handleKeyPress(e)}
            placeholder={t('TypeAndHitEnter')}
            fullWidth
            InputProps={{
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <Tooltip title={t('Search')} onClick={hanleBringValue}>
                            <IconButton tabIndex={-1}>
                                <Search />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
                endAdornment: !props?.disabled && (
                    <InputAdornment position="end">
                        {dataLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <ClearIcon onClick={handleClear} style={{ height: 18, cursor: 'pointer' }} />
                        )}
                    </InputAdornment>
                )
            }}
            name={name}
            {...other}
            onChange={handleChange}
        />
    );
};

export default BringValueInput;
