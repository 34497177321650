import { createContext, useContext, useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SIGNALR_PATH } from 'constants/appUrls';
import { useDispatch } from 'react-redux';
import { store } from 'store';
import { useQueryClient } from 'react-query';
import { ON } from 'enums/signalrConnection';
import { SIGNALR_DEVICE_UNPAIRED } from 'store/actions';

const MobileConnectionContext = createContext({});

const MobileConnectionProvider = (props) => {
    const { children } = props;
    const url = `${process.env.REACT_APP_BASE_URL}${SIGNALR_PATH}`;
    const state = store.getState();
    const dispatch = useDispatch();
    // const signalrState = useSelector((state) => state.signalr);
    const connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Information)
        .withUrl(url, { accessTokenFactory: () => state.auth.token })
        .withAutomaticReconnect()
        .build();

    const client = useQueryClient();

    const handleOnInvalidateQuery = (data) => {
        client.invalidateQueries(data);
    };

    /* const handleOnDevicePaired = () => {
        console.log('device paired');
    }; */

    const handleOnDeviceUnpaired = () => {
        dispatch({ type: SIGNALR_DEVICE_UNPAIRED });
    };

    useEffect(() => {
        if (connection && connection.state === 'Disconnected') {
            connection.start();
        }

        // connection.on(ON.devicePaired, handleOnDevicePaired);
        connection.on(ON.deviceUnpaired, handleOnDeviceUnpaired);
        connection.on(ON.invalidateQuery, handleOnInvalidateQuery);

        return () => {
            connection.stop();
        };
    }, [connection]);

    return <MobileConnectionContext.Provider value={{ connection }}>{children}</MobileConnectionContext.Provider>;
};

const useMobileConnection = () => {
    const mobileConnectionContext = useContext(MobileConnectionContext);
    if (!mobileConnectionContext) {
        throw new Error('useMobileConnection must be used within a MobileConnectionProvider');
    }
    return mobileConnectionContext;
};

export { MobileConnectionProvider, useMobileConnection };
