import { toGreeklish } from './replaceText';
import { toUpper } from 'lodash';

//  based on https://www.passport.gov.gr/passports/GrElotConverter/GrElotConverter.html
export const grk2Latin = (text) => {
    //  Convert a modern Greek characters text to ELOT743 (letter mapping).

    text = toGreeklish(text);

    // convert to uppercase
    text = toUpper(text);

    return text;
};
