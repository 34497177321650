import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BringValueRejectDialog = (props) => {
    const { open, setOpen, rejectReason, acceptWarning, setGridData, setOpenDialog, setDataKey } = props;
    const { t } = useTranslation();

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Rejection reason</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Typography variant="subtitle1" sx={{ whiteSpace: 'break-spaces' }}>
                                {rejectReason}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        try {
                            acceptWarning();
                            setOpenDialog(false);
                            setDataKey(null);
                            setGridData([]);
                            setOpen(false);
                        } catch (e) {
                            console.log(e, 'MelloAngelos');
                        }
                    }}
                    sx={{ background: '#4d79bc', ':hover': { background: '#79AEC9', color: '#fff' } }}
                >
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BringValueRejectDialog;

// import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Button, Typography } from '@mui/material';
// import { useTranslation } from 'react-i18next';

// const BringValueRejectDialog = (props) => {
//     const { open, setOpen, rejectReason } = props;
//     const { t } = useTranslation();

//     return (
//         <Dialog open={open} fullWidth>
//             <DialogTitle>Rejection reason</DialogTitle>
//             <DialogContent>
//                 <Grid container>
//                     <Grid item xs={12}>
//                         <Stack spacing={1}>
//                             <Typography variant="subtitle1" sx={{ whiteSpace: 'break-spaces' }}>
//                                 {rejectReason}
//                             </Typography>
//                         </Stack>
//                     </Grid>
//                 </Grid>
//             </DialogContent>
//             <DialogActions>
//                 <Button
//                     variant="contained"
//                     onClick={() => setOpen(false)}
//                     sx={{ background: '#4d79bc', ':hover': { background: '#79AEC9', color: '#fff' } }}
//                 >
//                     {t('Close')}
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     );
// };

// export default BringValueRejectDialog;
