import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
//  import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
import LanguageSection from './LanguageSection';

import ImpersonationSection from './ImpersonationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

import BreadcrumbSection from './BreadcrumbSection';
import FullNameSection from './FullNameSection';
import { useMe } from 'hooks/useMe';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const { whoami } = useMe();

    return (
        <Box sx={{ height: '51px', display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase disableRipple sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <BreadcrumbSection />
            <Box sx={{ flex: 1 }} />
            <ImpersonationSection />
            <LanguageSection />
            <FullNameSection firstName={whoami?.firstName} lastName={whoami?.lastName} />
            <ProfileSection />
        </Box>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
