import { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';
import { backoffice } from 'apis';
import { useBringValue } from '@qubiteq/datagrid';
import { isFunction } from 'lodash';

const useBringValueInput = ({ props, ref, runOnInit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { register, formState, setValue, getValues } = useFormContext();
    const { errors } = formState;
    const { name, rules, defaultIdValue, defaultDescriptionValue, dataKey, searchField, additionalFilters, onSelected, onNotFound } = props;
    const paramsRef = useRef(null);

    const handleBringValue = useCallback(
        async ({ dataKey, filters, ...rest }) => {
            try {
                const result = await backoffice
                    .getData({
                        dataKey,
                        pageSize: 20,
                        filters,
                        additionalFilters,
                        ...rest
                    })
                    .catch((err) => {
                        throw err;
                    });

                if (!result) {
                    throw new Error('No data');
                }

                const { itemsCount, items } = result;
                if (itemsCount === 0) {
                    if (onNotFound && isFunction(onNotFound)) onNotFound();
                    enqueueSnackbar('Δεν βρέθηκε εγγραφή', {
                        variant: 'warning'
                    });
                    setValue(name, null);
                    setValue(`${name}Description`, '');
                }

                return {
                    itemsCount,
                    items
                };
            } catch (err) {
                console.error(err);
                return {};
            }
        },
        [additionalFilters]
    );

    const handleSelectBringValue = ({ item }) => {
        setValue(name, item[paramsRef.current.primaryKeyName]);
        setValue(`${name}Description`, item[searchField]);
        if (onSelected) {
            onSelected(item);
        }
    };

    const bringValue = useBringValue(handleBringValue, backoffice.getParams, handleSelectBringValue);

    useEffect(() => {
        const initialFetch = async () => {
            const filters = [];
            const idValue = getValues()[name] || defaultIdValue;

            const params = await backoffice.getParams({ dataKey });
            paramsRef.current = params.params;
            if (idValue) {
                if (params?.params) {
                    filters.push({
                        filter: idValue,
                        operation: 'custom',
                        searchField: paramsRef.current.primaryKeyName
                    });
                }
            }
            const result = await handleBringValue({ dataKey, filters });
            if (result?.itemsCount === 1 && runOnInit) {
                handleSelectBringValue({ item: result.items[0] });
            }
        };

        initialFetch();
    }, [handleBringValue]);

    useEffect(() => {
        setValue(name, defaultIdValue);
    }, [setValue, name, defaultIdValue]);

    useEffect(() => {
        setValue(`${name}Description`, defaultDescriptionValue);
    }, [defaultDescriptionValue]);

    const onChange = (e) => {
        // setValue(`${name}Description`, e.target.value);
        if (e.target.value === '') {
            setValue(name, null);
        }
    };

    const onKeyDown = async (e) => {
        if (e.key === 'Enter') {
            const filter = [
                {
                    searchField,
                    filter: `${e.target.value}*`,
                    operation: 'custom'
                }
            ];
            await bringValue.bringValue({ e }, dataKey, filter);
        }
    };

    const clear = () => {
        setValue(name, null);
        setValue(`${name}Description`, '');
        if (onNotFound && isFunction(onNotFound)) onNotFound();
    };

    return {
        bringValue,
        registerProps: register(name, rules),
        descriptionRegisterProps: register(`${name}Description`, rules),
        errors,
        onKeyDown,
        onChange,
        clear
    };
};

export default useBringValueInput;
