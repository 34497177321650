import { getPerson, getPersonFromUser } from 'apis/controllers/person';
// react query
import { useQuery } from 'react-query';

const fetchPerson = async ({ queryKey }) => {
    const personId = queryKey[1];

    const result = await getPerson({ personId });

    return result;
};

const fetchPersonFromUser = async ({ queryKey }) => {
    const userId = queryKey[1];

    const result = await getPersonFromUser({ userId });
    return result;
};

const defaultOptions = {
    fromUser: false
};

export default function usePerson(id, options = defaultOptions) {
    const { fromUser } = options;
    return useQuery(['person', id], (...params) => {
        if (fromUser) {
            return fetchPersonFromUser(...params);
        }
        return fetchPerson(...params);
    });
}
