// material-ui
import { Link, Typography, Stack } from '@mui/material';
import qb from 'assets/images/qb.png';
import PDF from 'assets/TermsOfUse.pdf';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generic } from 'apis';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => {
    const { t } = useTranslation();
    // const assetsQuery = useQuery(['assets'], async () => {
    //     const assets = await generic.getAssets();
    //     return assets;
    // });

    return (
        <div className="footer">
            <div className="footer-logo">
                <a href="https://qubiteq.gr/">
                    <img src={qb} width={32} alt="Qb" />
                </a>
            </div>

            {/* <Typography
                variant="subtitle2"
                component={Link}
                onClick={() => window.open(assetsQuery?.data?.termsOfUse)}
                sx={{ cursor: 'pointer' }}
                underline="hover"
            > */}
            <div className="footer-terms">
                <Typography
                    variant="subtitle2"
                    component={Link}
                    // onClick={() => window.open(PDF)}
                    onClick={() => window.open(`${window.location.origin}/TermsOfUse`, '_blank')}
                    sx={{ cursor: 'pointer' }}
                    underline="hover"
                >
                    {t('TermsOfUse')}
                </Typography>
            </div>

            <div className="footer-signature">
                <div className="footer-signature-flex">
                    <Typography sx={{ fontWeight: 700, marginRight: 0.6 }}>NFC Sports || Powered by </Typography>
                    <Typography
                        href="https://qubiteq.gr/"
                        component={Link}
                        underline="hover"
                        target="_blank"
                        style={{ color: '#ee2d1f', fontWeight: 700 }}
                    >
                        QUBITEQ
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default AuthFooter;
