import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import { Logo } from '@qubiteq/qublib';
// material-ui
// import logo from 'assets/images/logo.png';
import { useSettings } from 'hooks/useSettings';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const { projectLogo } = useSettings();
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <Logo src={projectLogo} />
        </ButtonBase>
    );
};

export default LogoSection;
