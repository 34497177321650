import React, { useEffect, useRef } from 'react';
import { Stack, TextField, Typography, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { BringValueView, GridPropsProvider } from '@qubiteq/datagrid';
import { IconSearch, IconX } from '@tabler/icons';

import useBringValueInput from './useBringValueInput';
/* eslint-disable react/jsx-no-duplicate-props */

const BringValueInput = (props, ref) => {
    const { name, rules, runOnInit = true, label, ...other } = props;
    const { registerProps, descriptionRegisterProps, errors, bringValue, onKeyDown, onChange, clear } = useBringValueInput({
        props,
        ref,
        runOnInit
    });

    delete other.defaultDescriptionValue;
    delete other.defaultIdValue;
    delete other.searchField;
    delete other.dataKey;
    delete other.additionalFilters;
    delete other.onNotFound;
    delete other.onSelected;

    return (
        <>
            {/* Temporary fix */}
            <GridPropsProvider gridProps={{ getGrid: () => null }}>
                <BringValueView id={bringValue.id} handleOnEnter={bringValue.handleSelectBringValue} />
            </GridPropsProvider>
            <Stack>
                <input hidden {...registerProps} />
                {typeof label === 'string' ? <Typography>{label}</Typography> : label}
                <TextField
                    sx={{
                        borderColor: 'red'
                    }}
                    autoComplete="off"
                    {...descriptionRegisterProps}
                    inputProps={{
                        onKeyDown,
                        onChange
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconSearch style={{ height: 18 }} />
                            </InputAdornment>
                        ),
                        // eslint-disable-next-line react/destructuring-assignment
                        endAdornment: !props?.disabled && (
                            <InputAdornment position="end">
                                <IconX onClick={clear} style={{ height: 18, cursor: 'pointer' }} />
                            </InputAdornment>
                        )
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors[name]}
                    helperText={errors[name]?.message}
                    required={rules?.required}
                    size="small"
                    fullWidth
                    {...other}
                />
            </Stack>
        </>
    );
};

// BringValueInput.propTypes = {
//     name: PropTypes.string,
//     rules: PropTypes.any,
//     label: PropTypes.string
// };

export default React.forwardRef(BringValueInput);
