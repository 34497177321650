import axiosInstance from '../axiosInstance';

export const transactionApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`/transaction${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, options, cancelToken) =>
        axiosInstance
            .get(
                `/transaction${path}`,
                { ...params, ...options },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const gettransaction = async (params, options) => transactionApi.get('/gettransaction', { params }, options);

export const createOrUpdateTransactionRequest = async (params) => transactionApi.post('/createorupdatetransactionrequest', params);

export const changeStatus = async (params) => transactionApi.post('/changeStatus', params);
export const getTransactionRequiredFiles = async (params) => transactionApi.get('/GetTransactionRequiredFiles', { params });
export const uploadFiles = async (params, options) => transactionApi.post('/UploadFile', params, options);
export const deleteFile = async (params, options) => transactionApi.post('/RemoveFile', params, options);
export const getFiles = async (params) => transactionApi.post('/GetFiles', params);
export const downloadFile = async (params, options) => transactionApi.post('/DownloadFile', params, options);
export const removeFile = async (params) => transactionApi.post('/RemoveFile', params);
export const paymentEmail = async (params) => transactionApi.post('/PaymentEmail', params);
// export const transactionDetails = async (params) => transactionApi.post('/GetTransactionDetails', params);
export const splitMerge = async (params) => transactionApi.post('/SplitMerge', params);
export const getClubAdminsEmails = async (params) => transactionApi.get('/GetClubAdmisEmails', { params });
export const submitPayment = async (params, options) => transactionApi.post('/PaymentSubmittion', params, options);
export const manage = async (params) => transactionApi.get('/ManageTransaction', { params });
export const getTransactionSettings = async (params) => transactionApi.get('/GetTransactionSettings', { params });
export const paymentLink = async (params) => transactionApi.get('/CreatePaymentLink', { params });
export const newTransactionOptions = async (params) => transactionApi.get('/NewTransactionOptions', { params });
