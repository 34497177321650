import { Box, Typography, Stack, Divider, Chip, Avatar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { getAvatar } from 'apis/controllers/person';
import { useNavigate } from 'react-router';

const PersonInfo = ({ person }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState('');

    useEffect(() => {
        const fetch = async () => {
            const result = await getAvatar({ recordGuid: person.id });
            setAvatar(result);
        };

        fetch();
    }, [person.id]);

    return (
        <Box display="flex" direction="row" style={{ width: '100%' }}>
            <Avatar
                src={avatar === '' ? require('../../assets/images/default-user-image.png').default : avatar}
                alt="No "
                sx={{ width: 100, height: 100, marginRight: 6 }}
            />
            <Box style={{ width: '100%' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Stack direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        <>
                            <Typography sx={{ fontWeight: 500 }} variant="h4">{`${person.firstName} ${person.lastName}`}</Typography>
                            {person.fathersName && <Typography variant="body2">{`του ${person.fathersName}`}</Typography>}
                        </>
                        <Typography variant="body1">{person.gender.description}</Typography>
                    </Stack>
                    <div>
                        <Chip label={person.status?.description} style={{ marginRight: 5 }} />
                        <Chip label={person.status?.description} />
                    </div>
                </Box>
                {person.dob && (
                    <Stack>
                        <Typography variant="body1">{format(new Date(person.dob), 'dd/MM/yyyy')}</Typography>
                    </Stack>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {person.hasUser ? (
                        <Button
                            sx={{ padding: 1, marginRight: 1, marginTop: 1 }}
                            variant="outlined"
                            onClick={() => navigate(`/users-and-access/users/manage/${person?.id}/details`)}
                        >
                            {t('ViewUser')}
                        </Button>
                    ) : (
                        <Button
                            sx={{ padding: 1, marginRight: 1, marginTop: 1 }}
                            variant="outlined"
                            onClick={() => navigate(`/users-and-access/users/create/${person?.id}`)}
                        >
                            {t('CreateNewUser')}
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

PersonInfo.propTypes = {
    person: PropTypes.object
};

export default PersonInfo;
