/* eslint-disable */
// React Imports
import { useState, forwardRef, createRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

// MUI Imports
import { InputAdornment, IconButton, ThemeProvider, Stack, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DateRangeSharp } from '@mui/icons-material';

// Other Libraries
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Project Imports
import { formatToShowDateTime } from 'qubCommon/helperMethods/dateHelper';
import { CssTextField } from './CssTextField';
import { parse } from 'date-fns';
import RowLabel from './RowLabel';
import ColumnLabel from './ColumnLabel';

const DateTimeInput = (props) => {
    const { dateFormat } = useSelector((state) => state.auth);
    const dateTimeFormat = dateFormat?.format + ' HH:mm';
    const { setValue } = useFormContext();
    const { fieldsVariant } = useSelector((state) => state.customization);

    const {
        defaultDate = '',
        defaultTime = '',
        locked = false,
        label = '',
        cmpyrsValidation = false,
        variant = fieldsVariant ?? 'standard',
        name,
        labelWidth = 120,
        rowDirection = false,
        tooltip,
        ...other
    } = props;

    const [input, setInput] = useState(defaultDate ? format(new Date(defaultDate + ' ' + defaultTime), dateTimeFormat) : '');
    const [datePickerValue, setDatePickerValue] = useState(
        defaultDate ? format(new Date(defaultDate), dateFormat?.format) : format(new Date(), dateFormat?.format)
    );
    const dateRef = createRef();

    const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
        <IconButton onClick={onClick} ref={ref}>
            <DateRangeSharp />
        </IconButton>
    ));

    useEffect(() => {
        if (defaultDate && defaultTime) setValue(name, defaultDate + ' ' + defaultTime);
    }, []);

    return (
        <ThemeProvider theme={{}}>
            <Stack direction={rowDirection ? 'row' : 'column'}>
                {variant === 'standard' && (
                    <>
                        {rowDirection ? ( // variant = 'standard'
                            <RowLabel labelWidth={labelWidth} label={label} />
                        ) : (
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <ColumnLabel label={label} />
                                {tooltip && (
                                    <Tooltip title={tooltip}>
                                        <HelpOutlineIcon fontSize="small" />
                                    </Tooltip>
                                )}
                            </Stack>
                        )}
                    </>
                )}
                <CssTextField
                    disabled={locked}
                    variant={variant}
                    size="small"
                    onBlur={() => {
                        let dateTimePicked = formatToShowDateTime(input, dateFormat, dateTimeFormat, defaultDate, defaultTime);
                        setInput(dateTimePicked ?? '');
                        setValue(name, format(parse(dateTimePicked, dateFormat.format + ' HH:mm', new Date()), 'yyyy-MM-dd HH:mm'));
                        setDatePickerValue(
                            format(parse(dateTimePicked, dateTimeFormat, new Date()), dateFormat?.format) ??
                                format(new Date(), dateFormat?.format)
                        );
                    }}
                    style={{ margin: 1 }}
                    value={input}
                    onChange={(e) => setInput(e?.target?.value)}
                    InputProps={
                        locked
                            ? { disableUnderline: true }
                            : {
                                  disableUnderline: true,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <DatePicker
                                              selected={parse(datePickerValue, dateFormat?.format, new Date())}
                                              onChange={(e) => {
                                                  setInput(format(e, dateFormat?.format) + ' ' + input?.slice(-5));
                                                  setValue(name, format(e, dateFormat?.format) + ' ' + input?.slice(-5));
                                                  setDatePickerValue(format(e, dateFormat?.format));
                                              }}
                                              customInput={<CustomDatePicker ref={dateRef} />}
                                              showYearDropdown
                                          />
                                      </InputAdornment>
                                  )
                              }
                    }
                    {...other}
                />
            </Stack>
        </ThemeProvider>
    );
};

export default DateTimeInput;
