import PropTypes from 'prop-types';
import { useState, lazy, useEffect, forwardRef, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography, Link } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

import { Loadable } from '@qubiteq/qublib';
import { MENU_OPEN } from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';

import config from 'config';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Can } from 'context/permissionContext';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const menuState = useSelector((state) => state.menu);
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const [openedId] = menuState.isOpen;
        if (openedId === menu.id || (openedId > -1 && menu?.children.findIndex((child) => child.id === openedId) > -1)) {
            setOpen(true);
            setSelected(menu.id);
        } else {
            setOpen(false);
            setSelected(null);
        }
    }, [menu, menuState]);

    /* useEffect(() => {
        if (menu?.url.includes(location?.pathname?.split('/')[1])) {
            dispatch({ type: MENU_OPEN, id: menu.id });
        }
        menu?.children.forEach((item) => {
            if (item?.url.includes(location?.pathname?.split('/')[1])) {
                dispatch({ type: MENU_OPEN, id: item.id });
            }
        });
    }, [location, dispatch, menu]); */

    const handleClick = () => {
        setOpen(!open);
        setSelected(menu.id);
        if (location.pathname !== `${config.basename}${menu.url}`) navigate(`${config.basename}${menu.url}`);
    };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        const [subject, action] = item.permission?.split('.') ?? [];
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return (
                    <Can I={action} a={subject} key={item.id} passThrough>
                        {(allowed) => (allowed || (!subject && !action)) && <NavItem key={item.id} item={item} level={level + 1} />}
                    </Can>
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = Loadable(
        useMemo(
            () =>
                lazy(() =>
                    import('@tabler/icons').then((module) => {
                        return {
                            default: module[menu.icon]
                        };
                    })
                ),
            [menu]
        )
    );

    const menuIcon = menu?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: menu.isOpen.findIndex((id) => id === menu?.id) > -1 ? 8 : 6,
                height: menu.isOpen.findIndex((id) => id === menu?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
                selected={selected === menu.id}
                // onClick={() => itemHandler(item.id)}
                onClick={handleClick}
                tabIndex={-1}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {t(menu.title)}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.light
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
