import axiosInstance from '../axiosInstance';

export const applicationsApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/applications${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/applications${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getApplicationsType = async (params) => applicationsApi.post('/getapplicationstypes', params);

export const getApplicationById = async (params) => applicationsApi.get('/getbyid', { params });

export const createApplication = async (params) => applicationsApi.post('/save', params);

export const changeStatus = async (params) => applicationsApi.post('/changestatus', params);

export const approval = async (params) => applicationsApi.post('/approval', params);

export const updateApplication = async (params) => applicationsApi.post('/update', params);

export const getSettings = async (params) => applicationsApi.get('/getSettings', { params });

export const getAllByGuid = async (params) => applicationsApi.get('/getallbyguid', { params });

export const getSettingsByApplicationType = async (params) => applicationsApi.get('/settingsByApplicationType', { params });
