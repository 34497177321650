import React, { createContext, useRef, useState, useContext, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button, Stack } from '@mui/material';
import propTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { DynamicForm, useYupValidationResolver } from '@qubiteq/qublib';

const DialogContext = createContext();

function useDialog() {
    const dialogContext = useContext(DialogContext);
    const handleSubmit = dialogContext.form?.handleSubmit;
    const reset = dialogContext.form?.reset;

    const openDialog = (props) => {
        const { title, description, acceptButtonTitle, cancelButtonTitle, onClose, onAccept, form, validationSchema, render, watchField } =
            props;

        dialogContext.setOpen(true);
        dialogContext.setTitle(title);
        dialogContext.setDescription(description);
        dialogContext.setAcceptButtonTitle(acceptButtonTitle);
        dialogContext.setCancelButtonTitle(cancelButtonTitle);
        dialogContext.setFormJSON(form);
        dialogContext.setValidationSchema(validationSchema);
        if (watchField) {
            dialogContext.watch(watchField);
        }
        dialogContext.mutateOnClose(() => {
            if (onClose) {
                onClose();
            }
            dialogContext.watchersUnsubscribe();
            dialogContext.mutateRender(null);
            dialogContext.setOpen(false);
            if (reset !== undefined || reset !== null) {
                dialogContext.setFormJSON([]);
                reset({});
            }
        });
        if (onAccept) {
            dialogContext.mutateOnAccept(
                handleSubmit((data) => {
                    onAccept(data);
                    dialogContext.setOpen(false);
                    if (reset !== undefined || reset !== null) {
                        reset({});
                    }
                })
            );
        }
        if (render) {
            dialogContext.mutateRender(render);
        }
    };
    // dialogContext.onClose.current();
    return { openDialog, form: dialogContext.form, setForm: dialogContext.setFormJSON, formJSON: dialogContext.formJSON };
}

const DialogProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [acceptButtonTitle, setAcceptButtonTitle] = useState('');
    const [cancelButtonTitle, setCancelButtonTitle] = useState('');
    const [formJSON, setFormJSON] = useState([]);
    const [validationSchema, setValidationSchema] = useState(null);
    const [render, setRender] = useState(null);
    const onAccept = useRef(() => setOpen(false));
    const onClose = useRef(() => setOpen(false));
    const watchers = useRef([]);

    const mutateOnAccept = (callBack) => {
        onAccept.current = callBack;
    };

    const mutateOnClose = (callBack) => {
        onClose.current = callBack;
    };

    const mutateRender = useCallback((render) => {
        setRender(render);
    }, []);

    const watchersUnsubscribe = () => {
        watchers.current.forEach((watcher) => watcher.unsubscribe());
    };

    const resolver = useYupValidationResolver(validationSchema);

    const form = useForm({ resolver: validationSchema ? resolver : null });

    const watch = (watchFunction) => {
        const watcher = form.watch(watchFunction);
        watchers.current.push(watcher);
    };

    useEffect(() => {
        return watchersUnsubscribe;
    }, []);

    return (
        <>
            <Dialog open={open} onClose={onClose.current} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                    <Stack spacing={1} sx={{ mt: 2 }}>
                        {formJSON && <DynamicForm form={formJSON} methods={form} />}
                    </Stack>
                    {render}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose.current} color="primary" autoFocus>
                        {cancelButtonTitle}
                    </Button>
                    <Button variant="contained" onClick={onAccept.current} color="primary">
                        {acceptButtonTitle}
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogContext.Provider
                value={{
                    setOpen,
                    setTitle,
                    setDescription,
                    setAcceptButtonTitle,
                    setCancelButtonTitle,
                    mutateOnAccept,
                    mutateOnClose,
                    mutateRender,
                    onClose,
                    setFormJSON,
                    form,
                    formJSON,
                    watch,
                    watchersUnsubscribe,
                    setValidationSchema
                }}
            >
                {children}
            </DialogContext.Provider>
        </>
    );
};

DialogProvider.propTypes = {
    children: propTypes.node
};

const DialogConsumer = DialogContext.Consumer;

export { DialogProvider, DialogConsumer, useDialog };
