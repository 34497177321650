/* eslint-disable import/no-cycle */
import axiosInstance from '../axiosInstance';

export const userApi = {
    post: async (path, params, cancelToken) =>
        axiosInstance
            .post(`/user${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                console.log('err', err);
                throw err.error ? err : err?.response;
            }),
    get: async (path, params, cancelToken) =>
        axiosInstance
            .get(
                `/user${path}`,
                { params },
                {
                    cancelToken: cancelToken?.token
                }
            )
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response;
            })
};

export const search = async (params) => userApi.post('/search', params);
export const data = async (params) => userApi.get('/data', { personId: params?.personId, userId: params?.userId });
