import { Box, Stack, Typography, Link } from '@mui/material';
import qb from 'assets/images/qb.png';
import { useTranslation } from 'react-i18next';

const PublicFooter = (props) => {
    const { desktop } = props;
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                flexGrow: 1,
                mt: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                px: desktop ? 4 : 2
            }}
        >
            {desktop ? (
                <>
                    <Box sx={{ width: '33%' }}>
                        <a href="https://qubiteq.gr/">
                            <img src={qb} width={32} alt="Qb" />
                        </a>
                    </Box>
                    <Box sx={{ width: '33%', justifyContent: 'center', display: 'flex' }}>
                        <Typography
                            variant="subtitle2"
                            component={Link}
                            // onClick={() => window.open(PDF)}
                            onClick={() => window.open(`${window.location.origin}/TermsOfUse`, '_blank')}
                            sx={{ cursor: 'pointer' }}
                            underline="hover"
                        >
                            {t('TermsOfUse')}
                        </Typography>
                    </Box>
                    <Box sx={{ width: '33%', justifyContent: 'flex-end', display: 'flex' }}>
                        <Stack direction="row" alignItems="center">
                            <Typography sx={{ fontWeight: 700, marginRight: 0.6 }}>NFC Sports || Powered by </Typography>
                            <Typography
                                href="https://qubiteq.gr/"
                                component={Link}
                                underline="hover"
                                target="_blank"
                                style={{ color: '#ee2d1f', fontWeight: 700 }}
                            >
                                QUBITEQ
                            </Typography>
                        </Stack>
                    </Box>
                </>
            ) : (
                <Stack spacing={1}>
                    <a href="https://qubiteq.gr/">
                        <img src={qb} width={32} alt="Qb" />
                    </a>
                    <Typography
                        variant="subtitle2"
                        component={Link}
                        // onClick={() => window.open(PDF)}
                        onClick={() => window.open(`${window.location.origin}/TermsOfUse`, '_blank')}
                        sx={{ cursor: 'pointer' }}
                        underline="hover"
                    >
                        {t('TermsOfUse')}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <Typography sx={{ fontWeight: 700, marginRight: 0.6 }}>NFC Sports || Powered by </Typography>
                        <Typography
                            href="https://qubiteq.gr/"
                            component={Link}
                            underline="hover"
                            target="_blank"
                            style={{ color: '#ee2d1f', fontWeight: 700 }}
                        >
                            QUBITEQ
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </Box>
    );
};

export default PublicFooter;
