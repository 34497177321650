import axiosInstance from '../axiosInstance';

export const emailToolApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`/emailTool${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/emailTool${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getOrganizations = async (params) => emailToolApi.get('/getOrganizations', { params });
