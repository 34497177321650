import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BringValueWarningDialog = (props) => {
    const { open, setOpen, warningReason, acceptWarning, setGridData, setOpenDialog, setDataKey } = props;
    const { t } = useTranslation();

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{t('Warning')}!</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Typography variant="subtitle1" sx={{ whiteSpace: 'break-spaces' }}>
                                {warningReason}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        try {
                            setOpenDialog(false);
                            setDataKey(null);
                            setGridData([]);
                            setOpen(false);
                        } catch (e) {
                            setOpen(false);
                            console.log('Error on BringValueWarningDialog:', e);
                        }
                    }}
                    sx={{ background: '#4d79bc', ':hover': { background: '#79AEC9', color: '#fff' } }}
                >
                    {t('OK')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BringValueWarningDialog;
