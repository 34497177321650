import { Box, Typography, Stack, Grid } from '@mui/material';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useTranslation } from 'react-i18next';
import { IconTrafficCone } from '@tabler/icons';
import { useEffect } from 'react';

const PageUnderConstruction = () => {
    const { t } = useTranslation();

    return (
        <Grid justifyContent="center" alignItems="center" container sx={{ height: '100%' }}>
            <Grid item xs={6} sm={4}>
                <Stack justifyContent="center" alignItems="center">
                    <Box
                        boxShadow={5}
                        sx={{
                            background: 'white',
                            width: 'inherit',
                            p: 2,
                            borderRadius: 3,
                            display: 'flex',
                            direction: 'row',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconTrafficCone size={30} />
                        </Box>
                        <Typography
                            paragraph
                            sx={{
                                marginLeft: 1,
                                fontSize: '1.5rem',
                                color: colors.grey700,
                                fontWeight: 800,
                                mb: 0
                            }}
                        >
                            {t('PageUnderConstruction')}
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default PageUnderConstruction;
