//  mui
import { Grid, Typography, Box, MenuItem, Icon, Select, FormControl, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';

import '@grapecity/wijmo.styles/wijmo.css';
//
import * as wjChart from '@grapecity/wijmo.react.chart';
import * as wjChartAnimate from '@grapecity/wijmo.react.chart.animation';
import { Palettes } from '@grapecity/wijmo.chart';
import { BreakfastDining } from '@mui/icons-material';
import { useNavigate } from 'react-router';

const StackedBarChartCard = ({ props, palette, chartInitialized }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [dataset, setDataset] = useState();
    const [classes, setClasses] = useState();

    useEffect(() => {
        const maxObject = props?.Data?.reduce((a, b) => (a.length > b.length ? a : b));
        const labels =
            maxObject === undefined ? [] : Object?.keys(maxObject)?.filter((key) => key !== 'Datakey' && key !== 'LoggingDatakey');
        setClasses(labels);

        const translatedDataset = props?.Data?.map((datum) => {
            let navUrl = null;
            switch (datum?.Datakey) {
                case 'Athletes':
                    navUrl = '/applications/athletes/view';
                    break;
                case 'Officials':
                    navUrl = '/applications/officials';
                    break;
                case 'Clubs':
                    navUrl = '/applications/clubs';
                    break;
                default:
                    break;
            }
            return {
                ...datum,
                Datakey: t(`${datum?.Datakey}`),
                nav: navUrl
            };
        });

        setDataset(translatedDataset);
    }, [t]);

    return (
        <Box container boxShadow={3} sx={{ borderRadius: 2, p: 2, width: '100%', background: theme.palette.background.paper }}>
            <Grid item>
                <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
                    {t(`${props?.Label}`)}
                </Typography>
            </Grid>
            <Grid item>
                <wjChart.FlexChart
                    chartType="Bar"
                    bindingX="Datakey"
                    stacking="Stacked"
                    itemsSource={dataset}
                    palette={palette}
                    // itemFormatter={chartItemFormatter}
                    // tooltipContent={`${t('{abbr}')}: <b>{value}</b>`}
                    style={{ border: '0px' }}
                    initialized={chartInitialized}
                >
                    <wjChart.FlexChartLegend position="Bottom" />
                    {classes?.map((elem, index) => (
                        <wjChart.FlexChartSeries key={index} name={t(elem)} binding={elem} />
                    ))}
                    <wjChart.FlexChartAxis wjProperty="axisY" reversed />
                    <wjChartAnimate.FlexChartAnimation />
                </wjChart.FlexChart>
            </Grid>
        </Box>
    );
};

export default StackedBarChartCard;
