import axiosInstance from '../axiosInstance';

export const qformApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/qform${path}/getwithconfiguration`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/qform${path}/getwithconfiguration`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getWithConfiguration = async (dataKey, params) => qformApi.post(`/${dataKey}`, params);
