import axiosInstance from '../axiosInstance';

export const validatorApi = {
    post: (path, params, options, cancelToken) =>
        axiosInstance
            .post(`/validator${path}`, params, {
                ...options,
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/validator${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const validateAmka = async (params) => validatorApi.post('/validateAmka', params);
export const validateAfm = async (params) => validatorApi.post('/validateAfm', params);
