import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiEntities } from 'apis';

const useOperators = () => {
    const { t } = useTranslation();
    const operators = [
        { id: 'beginsWith', operator: t('Begins') },
        { id: 'contains', operator: t('Contains') },
        { id: 'endsWith', operator: t('Ends') },
        { id: '=', operator: '=' },
        { id: '>', operator: '>' },
        { id: '>=', operator: '>=' },
        { id: '<', operator: '<' },
        { id: '<=', operator: '<=' },
        { id: '<>', operator: '<>' },
        { id: 'between', operator: t('Between') },
        { id: 'isNull', operator: 'NULL' },
        { id: 'isEmpty', operator: t('Empty') }
    ];
    return operators;
};

const useBringValue = () => {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [dataKey, setDataKey] = useState(null);
    const [title, setTitle] = useState('');

    const [filters, setFilters] = useState([]);
    const [restData, setRestData] = useState([]);

    const [searchFieldOptions, setSearchFieldOptions] = useState([]);
    const [operatorsOptions, setOperatorsOptions] = useState([]);

    const [searchField, setSearchField] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchOperator, setSearchOperator] = useState('');
    const [searchSecondaryValue, setSearchSecondaryValue] = useState('');

    const [secondValueField, setSecondValueField] = useState(false);

    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [acceptWarning, setAcceptWarning] = useState(null);

    const [rejectReason, setRejectReason] = useState('');

    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [warningReason, setWarningReason] = useState('');

    const [columns, setColumns] = useState([]);
    const [groupColumns, setGroupColumns] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [itemCount, setItemCount] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [showRowHeader, setShowRowHeader] = useState(true);
    const [showColHeaderGroup, setShowColHeaderGroup] = useState(true);
    const [pageSizeOptions, setPageSizeOptions] = useState([]);
    const [pageSize, setPageSize] = useState(null);
    const [cellCssMapping, setCellCssMapping] = useState([]);
    const operators = useOperators();

    const ref = useRef();
    const gridRef = useRef();

    const getOperators = () => {
        const selectedItem = searchFieldOptions?.find((field) => field?.fldSearchName === searchField);
        const dataType = selectedItem?.dataType;
        let availableOptions = operators;
        if (dataType === 'date' || dataType === 'datetime')
            availableOptions = availableOptions.filter((option) =>
                ['=', '>', '>=', '<', '<=', '<>', 'between', 'isNull'].includes(option.id)
            );
        else if (dataType === 'boolean') availableOptions = availableOptions.filter((option) => ['='].includes(option.id));
        else if (dataType === 'int' || dataType === 'decimal')
            availableOptions = availableOptions.filter((option) =>
                ['=', '>', '>=', '<', '<=', '<>', 'between', 'isNull'].includes(option.id)
            );

        return availableOptions;
    };

    useEffect(() => {
        setOperatorsOptions(getOperators());
        setSearchOperator(getOperators()[0]?.id);
    }, [searchField]);

    useEffect(() => {
        if (searchOperator === 'between') setSecondValueField(true);
        else {
            setSecondValueField(false);
            setSearchSecondaryValue('');
        }
    }, [searchOperator]);

    const gridInitialized = (flex) => {
        flex.stickyHeaders = true;
        flex.selectionMode = 'Row';
        flex.keyActionEnter = 'None';
        flex.isReadOnly = true;
        gridRef.current = flex;
        flex.hostElement.addEventListener('dblclick', (e) => {
            const ht = flex.hitTest(e.pageX, e.pageY);
            const view = ht?.grid?.collectionView;
            const currentItem = view.currentItem;
            if (currentItem && ht.cellType === 1) {
                if (currentItem?.IsBvWarning) {
                    setWarningReason(currentItem?.BvWarningReason);
                    setOpenWarningDialog(true);
                    setAcceptWarning(() => {
                        ref.current.onSelect(view.currentItem);
                    });
                } else if (currentItem?.IsBvDisabled) {
                    setRejectReason(currentItem?.BvDisabledReason);
                    setOpenRejectDialog(true);
                } else {
                    ref.current.onSelect(view.currentItem);
                    setOpenDialog(false);
                    setDataKey(null);
                    setGridData([]);
                }
            }
        });

        flex.hostElement.addEventListener('keydown', (e) => {
            const view = flex.collectionView;
            if (e.code === 'Enter' && view.currentItem) {
                if (view.currentItem?.IsBvDisabled) {
                    setRejectReason(view?.currentItem?.BvDisabledReason);
                    setOpenRejectDialog(true);
                } else if (view.currentItem?.IsBvWarning) {
                    setWarningReason(view?.currentItem?.BvWarningReason);
                    setAcceptWarning(ref.current.onSelect(view.currentItem));
                    setOpenWarningDialog(true);
                } else {
                    e.preventDefault();
                    e.stopPropagation();
                    ref.current.onSelect(view.currentItem);
                    setOpenDialog(false);
                    setDataKey(null);
                    setGridData([]);
                }
            }
        });

        flex.resizedColumn.addHandler((s, e) => {
            const col = e.panel.columns[e.col];
            apiEntities.getData({ dataKey: 'Qub-ColsWidth-Save', parameters: { fldName: col.binding, fldWidth: col.width, dataKey } });
        });

        flex.formatItem.addHandler((flex, e) => {
            const row = flex.rows[e.row];
            if (row?.dataItem?.IsBvDisabled) {
                row.cssClass = 'bv-disabled-row';
            }
            if (row?.dataItem?.IsBvWarning && !row?.dataItem?.IsBvDisabled) {
                row.cssClass = 'bv-warning-row';
            }
            if (e.panel.cellType === 3) {
                e.cell.innerHTML = `<div class='row-header'> ${e.panel.rows[e.row]?.index + 1}</div>`;
            }
        });
    };

    const selectItem = (select) => {
        if (select) {
            if (!gridRef.current?.collectionView?.currentItem?.IsBvDisabled && !gridRef.current?.collectionView?.currentItem?.IsBvWarning) {
                ref?.current?.onSelect(gridRef.current?.collectionView?.currentItem);
                setOpenDialog(false);
                setDataKey(null);
                setGridData([]);
            } else if (gridRef.current?.collectionView?.currentItem?.IsBvWarning) {
                setWarningReason(gridRef.current?.collectionView?.currentItem?.BvWarningReason);
                setOpenWarningDialog(true);
                setAcceptWarning(ref?.current?.onSelect(gridRef.current?.collectionView?.currentItem));
            } else {
                setRejectReason(gridRef.current?.collectionView?.currentItem?.BvDisabledReason);
                setOpenRejectDialog(true);
            }
        } else {
            ref?.current?.onSelect(t('NoItemSelected'));
            setOpenDialog(false);
            setDataKey(null);
            setGridData([]);
        }
    };

    // set criteria here
    const onSubmit = async () => {
        const fieldSearchParams = [
            { fldName: searchField, operator: searchOperator, value1: searchValue, value2: searchSecondaryValue },
            ...filters
        ];
        setDataLoading(true);
        setGridData([]);
        const res = await apiEntities.getData({
            dataKey: 'QUB-BringValue-DataGet',
            parameters: {
                bringValueKey: dataKey,
                critFldsSearch: fieldSearchParams,
                pageSize
            }
        });
        setItemCount(`${res?.ReturnedRecords}/${res?.TotalRecords} ${t('Rows')}` ?? '');
        setGridData(res?.data);
        setDataLoading(false);
    };

    return {
        openDialog,
        setOpenDialog,
        gridInitialized,
        ref,
        gridRef,
        dataKey,
        setDataKey,
        title,
        setTitle,
        columns,
        itemCount,
        setItemCount,
        setColumns,
        groupColumns,
        setGroupColumns,
        gridData,
        setGridData,
        selectItem,
        onSubmit,
        dataLoading,
        setDataLoading,
        showRowHeader,
        showColHeaderGroup,
        setShowRowHeader,
        setShowColHeaderGroup,
        pageSizeOptions,
        setPageSizeOptions,
        cellCssMapping,
        setCellCssMapping,
        criteria: {
            searchField,
            setSearchField,
            searchValue,
            setSearchValue,
            searchOperator,
            setSearchOperator,
            searchSecondaryValue,
            setSearchSecondaryValue,
            secondValueField,
            setSecondValueField,
            searchFieldOptions,
            setSearchFieldOptions,
            operatorsOptions,
            setFilters,
            restData,
            setRestData,
            setPageSize,
            pageSizeOptions,
            setPageSizeOptions
        },
        rejectDialog: {
            openRejectDialog,
            setOpenRejectDialog,
            rejectReason,
            setRejectReason,
            acceptWarning
        },
        warningDialog: {
            openWarningDialog,
            setOpenWarningDialog,
            warningReason,
            setWarningReason,
            acceptWarning,
            setAcceptWarning
        }
    };
};

export default useBringValue;
