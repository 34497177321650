// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store, persistor } from 'store';
import './assets/main.css';

// style + assets
import 'assets/scss/style.scss';
import '@grapecity/wijmo.styles/wijmo.css';

// i18n
import './i18n';

import '@qubiteq/datagrid/dist/datagrid.cjs.development.css';
import { Helpers } from '@qubiteq/datagrid';
import { setLicenseKey } from '@grapecity/wijmo';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';

// ==============================|| LICENSE WIJMO  ||============================== //

// setLicenseKey(window.CONFIG.WIJMO_LICENSE_KEY);
// Helpers.licenseWj(window.CONFIG.WIJMO_LICENSE_KEY);

/* eslint-disable */
// ==============================|| REACT DOM RENDER  ||============================== //

// ReactDOM.render(
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}
        TransitionComponent={Slide}
    >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </SnackbarProvider>
    // ,
    //  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
