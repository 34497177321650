const imgSizesCalc = (aspect) => {
    let imgWidth = 0;
    let imgHeight = 0;
    let cropWidth = 0;
    let cropHeight = 0;
    let defaultImg = require('assets/images/default-user-image.png').default;
    if (aspect === 4 / 5) {
        imgWidth = 120;
        imgHeight = 150;
        cropWidth = 200;
        cropHeight = 250;
        defaultImg = require('assets/images/default-user-image.png').default;
    } else if (aspect === 16 / 9) {
        imgWidth = 200;
        imgHeight = 112.5;
        cropWidth = 320;
        cropHeight = 180;
        defaultImg = require('assets/images/no-image-icon.png').default;
    } else if (aspect === 1 / 1) {
        imgWidth = 150;
        imgHeight = 150;
        cropWidth = 150;
        cropHeight = 150;
        defaultImg = require('assets/images/no-image-icon.png').default;
    }

    return { imgWidth, imgHeight, cropWidth, cropHeight, defaultImg };
};

export default imgSizesCalc;
